import $ from "jquery";
import { gsap } from 'gsap';

// Main js file
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';

document.addEventListener('DOMContentLoaded', () => {
    gsap.from('.content__decor', {
        translateY: '30vh',
        scale: 0,
        opacity: 0,
        duration: 1,
    });
    gsap.from('.intro__banner', {
        opacity: 0,
        scale: 0,
        duration: 0.5,
        ease: "expo.inOut"
    });
});

(function () {
    let trigger = document.querySelector('.js-menu-trigger'),
        mobMenu = document.querySelector('.lang-switch');
    if (trigger) {
        document.addEventListener('click', function (event) {
            if (mobMenu && document.body.classList.contains('menu-open')) {
                var isClickInside = mobMenu.contains(event.target);
                if (!isClickInside && !trigger.contains(event.target)) {
                    document.body.classList.remove('menu-open');
                }
            }
        });
        trigger.onclick = () => {
            document.body.classList.toggle('menu-open');
        };
    }
})();